import { mapObject, mapToObject } from "../utils/objects";
import {
  AnalyticsBucketMetricDto,
  AnalyticsBucketOfBucketMetricDto as AnalyticsBucketOfBucketedValuesMetricDto,
  AnalyticsSingleValueDto,
  AnalyticsSingleValueGroupDto,
} from "./Analytics.dtoAdditions";
import {
  AnalyticsBucket,
  AnalyticsBucketedMetric,
  AnalyticsBucketedMetricValue,
  AnalyticsBucketOfBucketedMetric,
  AnalyticsBucketOfBucketedMetricValue,
  AnalyticsCategory,
  AnalyticsGroupingInterval,
  AnalyticsMetricName,
  AnalyticsResults,
  AnalyticsResultsApendix,
  AnalyticsResultsApendixEntry,
  AnalyticsSingleMetric,
  AnalyticsGroupMetric,
  AnalyticsUnit,
  AnalyticsMetricWUnitBase,
  AnalyticsMetricBase,
} from "./Analytics.types";
import {
  AbstractMetric as AbstractMetricDto,
  AnalyticsBucket as AnalyticsBucketDto,
  AnalyticsGroupingInterval as AnalyticsGroupingIntervalDto,
  AnalyticsMetricName as AnalyticsMetricNameDto,
  AnalyticsResults as AnalyticsResultsDto,
  AnalyticsUnit as AnalyticsUnitDto,
  Appendix as AppendixDto,
  EventCategory as EventCategoryDto,
  RichAppendixEntry as RichAppendixEntryDto,
} from "./client";
import { dtoToThinPerson } from "./Users";

/** string union mutators */

export const dtoToAnalyticsBucket = <B extends AnalyticsBucket>(dto: AnalyticsBucketDto): B => dto as B;
// TODO: This cast sucks.  B.E. thinks this is EventCategory (see: AnalyticsRowEventCategoryLong).
export const dtoToAnalyticsEventCategory = (dto: EventCategoryDto): AnalyticsCategory =>
  dto as unknown as AnalyticsCategory;
export const dtoToAnalyticsUnit = <U extends AnalyticsUnit>(dto: AnalyticsUnitDto): U => dto as U;
export const analyticssMetricToDto = (metric: AnalyticsMetricName): AnalyticsMetricNameDto =>
  metric as AnalyticsMetricNameDto;
export const analyticsGroupingIntervalToDto = (interval: AnalyticsGroupingInterval): AnalyticsGroupingIntervalDto =>
  interval as AnalyticsGroupingIntervalDto;

/** Supporting mutators */

export const dtoToBucketedValuesValue = <BUCKET extends AnalyticsBucket>(
  dto
): AnalyticsBucketedMetricValue<BUCKET> => ({ ...dto });

export const dtoToBucketOfBucketedValuesValue = <BUCKET extends AnalyticsBucket, SUB_BUCKET extends AnalyticsBucket>(
  dto
): AnalyticsBucketOfBucketedMetricValue<BUCKET, SUB_BUCKET> => ({ ...dto });

/** AnalyticsValue mutators */

export const dtoToAnalyticsValueBase = <METRIC extends AnalyticsMetricName>(
  metricName: AnalyticsMetricNameDto,
  dto: AbstractMetricDto
): AnalyticsMetricBase<METRIC> => ({
  metric: metricName as METRIC,
  description: dto.description,
});

export const dtoToAnalyticsUnitedValueBase = <METRIC extends AnalyticsMetricName, UNIT extends AnalyticsUnit>(
  metricName: AnalyticsMetricNameDto,
  dto: AbstractMetricDto
): AnalyticsMetricWUnitBase<METRIC, UNIT> => ({
  ...dtoToAnalyticsValueBase(metricName, dto),
  unit: dtoToAnalyticsUnit<UNIT>(dto.unit),
  noData: dto.noData,
});

export const dtoToAnalyticsSingleValue = <METRIC extends AnalyticsMetricName, UNIT extends AnalyticsUnit>(
  metricName: AnalyticsMetricNameDto,
  dto: AnalyticsSingleValueDto
): AnalyticsSingleMetric<METRIC, UNIT> => ({
  ...dtoToAnalyticsUnitedValueBase<METRIC, UNIT>(metricName, dto),
  value: dto.value,
});

export const dtoToAnalyticsBucketedValue = <
  METRIC extends AnalyticsMetricName,
  UNIT extends AnalyticsUnit,
  BUCKET extends AnalyticsBucket,
>(
  metricName: AnalyticsMetricNameDto,
  dto: AnalyticsBucketMetricDto
): AnalyticsBucketedMetric<METRIC, UNIT, BUCKET> => ({
  ...dtoToAnalyticsUnitedValueBase<METRIC, UNIT>(metricName, dto),
  values: dto.values.map((value) => dtoToBucketedValuesValue(value)),
  bucket: dtoToAnalyticsBucket(dto.bucket!),
});

export const dtoToAnalyticsBucketOfBucketValues = <
  METRIC extends AnalyticsMetricName,
  UNIT extends AnalyticsUnit,
  BUCKET extends AnalyticsBucket,
  SUB_BUCKET extends AnalyticsBucket,
>(
  metricName: AnalyticsMetricNameDto,
  dto: AnalyticsBucketOfBucketedValuesMetricDto
): AnalyticsBucketOfBucketedMetric<METRIC, UNIT, BUCKET, SUB_BUCKET> => ({
  ...dtoToAnalyticsUnitedValueBase<METRIC, UNIT>(metricName, dto),
  bucket: dtoToAnalyticsBucket(dto.bucket!),
  subBucket: dtoToAnalyticsBucket(dto.subBucket!),
  values: dto.values.map((value) => dtoToBucketOfBucketedValuesValue(value)),
});

export const dtoToAnalyticsSingleValueGroup = <METRIC extends AnalyticsMetricName>(
  metricName: AnalyticsMetricNameDto,
  dto: AnalyticsSingleValueGroupDto
): AnalyticsGroupMetric<METRIC> => ({
  ...dtoToAnalyticsValueBase(metricName, dto),
  metrics: dto.metrics.map(({ name, metric }) => dtoToAnalyticsSingleValue(name, metric)),
});

export const dtoToAnalyticsValues = <D extends {}>(metricName: AnalyticsMetricNameDto, dto: D) => {
  if ("subBucket" in dto)
    return dtoToAnalyticsBucketOfBucketValues(metricName, dto as unknown as AnalyticsBucketOfBucketedValuesMetricDto);
  if ("bucket" in dto) return dtoToAnalyticsBucketedValue(metricName, dto as unknown as AnalyticsBucketMetricDto);
  if ("value" in dto) return dtoToAnalyticsSingleValue(metricName, dto as unknown as AnalyticsSingleValueDto);
  if ("metrics" in dto)
    return dtoToAnalyticsSingleValueGroup(metricName, dto as unknown as AnalyticsSingleValueGroupDto);
};

export const dtoToAnalyticsResultsApendixEntry = <T>(
  dto: RichAppendixEntryDto,
  dtoTo: (dto: unknown) => T
): AnalyticsResultsApendixEntry<T> => ({
  missingScopes: dto.missingScopes,
  values: mapObject(dto.values, ([key, value]) => [key, dtoTo(value)]),
});

export const dtoToAnalyticsResultsApendix = (dto: AppendixDto): AnalyticsResultsApendix => ({
  people: dto.people && dtoToAnalyticsResultsApendixEntry(dto.people, dtoToThinPerson),
  teamSize: dto.teamSize,
});

/**
 * response mutators
 */

export const dtoToAnalyticsMetrics = <METRICS extends AnalyticsMetricName[]>(
  dto: AnalyticsResultsDto
): AnalyticsResults<METRICS> => ({
  metrics: {
    ...(mapToObject(dto.metrics, ({ metric, name }) => [
      name,
      dtoToAnalyticsValues(name, metric),
    ]) as unknown as AnalyticsResults<METRICS>["metrics"]),
    TIME_WITH_PEOPLE_DURATION_BY_PERSON: {
      bucket: "EMAIL",
      description: "",
      metric: "TIME_WITH_PEOPLE_DURATION_BY_PERSON",
      noData: false,
      unit: "DURATION_MINUTES",
      values: [
        {
          key: "a0@example.com",
          value: 200,
        },
        {
          key: "a1@example.com",
          value: 100,
        },
        {
          key: "a2@example.com",
          value: 100,
        },
        {
          key: "a3@example.com",
          value: 200,
        },
        {
          key: "a4@example.com",
          value: 100,
        },
        {
          key: "a5@example.com",
          value: 100,
        },
        {
          key: "a6@example.com",
          value: 200,
        },
        {
          key: "a7@example.com",
          value: 100,
        },
        {
          key: "a8@example.com",
          value: 100,
        },
        {
          key: "a9@example.com",
          value: 200,
        },
        {
          key: "a10@example.com",
          value: 100,
        },
        {
          key: "a11@example.com",
          value: 100,
        },
        {
          key: "a12@example.com",
          value: 200,
        },
        {
          key: "a13@example.com",
          value: 100,
        },
        {
          key: "a14@example.com",
          value: 100,
        },
        {
          key: "a15@example.com",
          value: 200,
        },
        {
          key: "a16@example.com",
          value: 100,
        },
        {
          key: "a17@example.com",
          value: 100,
        },
      ],
    },
  },
  appendix: dtoToAnalyticsResultsApendix(dto.appendix),
});
